import React, {useState,useContext,useRef} from 'react';
import "./Plus.scss";
import "shared/components/MarkDown.scss";
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react';
import SwiperCore, {
  Pagination
} from 'swiper';
import 'swiper/swiper.scss';
import 'swiper/modules/pagination/pagination.scss';
import Conf from 'Conf';
import { Scrollbars } from 'react-custom-scrollbars-2';
import Modal from 'shared/components/Modal';
import MuiMarkdown from 'mui-markdown';
import { ModelContext } from "providers/ModelProvider";

SwiperCore.use([Pagination]);

function Plus({portraitId,show,file=(url)=>url,...otherProps}) {
  const plus=useRef(null);
  const { getDoc } = useContext(ModelContext);
  const portrait=getDoc(portraitId);
  const [ preventClick,setPreventClick] = useState(false);
  let classes='plus';
  if (portrait && portrait.devenuImages && portrait.devenuImages.length>0) classes+=' has-images';
  const handlePreventClick=()=>{
    setPreventClick(true);
    const revert=()=>{
      setTimeout(()=>setPreventClick(false),100);
      plus.current.removeEventListener('pointerup',revert);
      plus.current.parentNode.parentNode.removeEventListener('pointerup',revert);
    };
    plus.current.addEventListener('pointerup',revert);
    plus.current.parentNode.parentNode.addEventListener('pointerup',revert);
  }
  const content= portrait ? <div className={classes} ref={plus}>
    <div className='plus-content'>
      <Scrollbars
      style={{ position:'absolute', width: '100%', height: '100%' }}
      autoHide
      >
        <div className='plus-content-inner'>
          <h3>{portrait.devenuTitre}</h3>
          {portrait.devenuTexte && <div className="markdown-preview"><MuiMarkdown>{portrait.devenuTexte}</MuiMarkdown></div>}
        </div>
      </Scrollbars>
      <div className="top-gradient"></div>
      <div className="bottom-gradient"></div>
    </div>
    {portrait && portrait.devenuImages && portrait.devenuImages.length>0 && <div
    onPointerDown={()=>handlePreventClick()}
    className='plus-images'>
      <Swiper
        pagination={{ clickable: true, type:'bullets' }}
        spaceBetween={0}
        slidesPerView={1}
        touchStartPreventDefault={false}
      >
        {portrait.devenuImages.map((img,i)=><SwiperSlide key={img.url+'-'+i}><img src={file(Conf.filesUrl+img.url+'-600')} alt=""/></SwiperSlide>)}
      </Swiper>
    </div>}
  </div> : null;
  return <Modal content={content} show={show} {...otherProps} preventClick={preventClick}/>;
}
export default Plus;
