import React, {useContext, useMemo} from 'react';
import "./MainMenu.scss";
import { ModelContext } from "providers/ModelProvider";

function Historique() {
  const { getCollection,setHistory,history } = useContext(ModelContext);
  const application=useMemo(()=>getCollection('application'),[getCollection]);
  const app=useMemo(()=>application[0] || {},[application]);
  return app.historique_titre ? <div>
    <p>{app.historique_texte_conversations}</p>
    {Object.keys(history).length>0 ?
      <div className="reset" onClick={()=>setHistory({})}></div>
    :
      <div className="reset-ok"></div>
    }
  </div> : '';
}
export default Historique;
