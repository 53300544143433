import React, { useMemo,useContext,useEffect,useState,useRef,useCallback } from "react";
import "./Loader.scss";
import Lottie from 'react-lottie-player'
import { ModelContext } from "providers/ModelProvider";
import { SettingsContext } from "providers/SettingsProvider";
import { PreloadContext } from "providers/PreloadProvider";
import { MixContext } from "shared/providers/MixProvider";
import { HelperContext } from "providers/HelperProvider";
import Conf from 'Conf';
import JSZip from 'jszip';
import JSZipUtils from 'jszip-utils';

function Loader({loaded,needAction,onDone}) {
  const { triggerSon,clearContext,mixerOk } = useContext(MixContext);
  const { setAppState } = useContext(SettingsContext);
  const { setHelper } = useContext(HelperContext);
  const { file } = useContext(PreloadContext);
  const { getCollection } = useContext(ModelContext);
  const [ animation,setAnimation ] = useState(null);
  const [ wait,setWait ] = useState(true);
  const [ done,setDone ] = useState(false);
  const [ opener,setOpener ] = useState(true);
  const [ splashLoad,setSplashLoad ] = useState(false);
  const [ skipDelay,setSkipDelay ] = useState(false);
  const init=useRef(false);
  const contextCache=useRef('opener');
  useEffect(()=>{
    if (!opener && !done) {
      const t=setTimeout(()=>setWait(false),8000);
      const t2=setTimeout(()=>setSkipDelay(true),11000);
      return ()=>{
        clearTimeout(t);
        clearTimeout(t2);
      }
    }
  },[opener,done]);
  const handleSplashLoad=()=>{
    setSplashLoad(true);
  }
  const texts=useMemo(()=>getCollection('interface'),[getCollection]);
  const chargement=useMemo(()=>texts.find((o)=>o.slug==='chargement'),[texts]);
  const application=useMemo(()=>getCollection('application'),[getCollection]);
  const app=useMemo(()=>application[0] || {},[application]);
  const getEntries = (url) => {
    init.current=true;
    JSZipUtils.getBinaryContent(url, (err, data)=>{
      if(err) {
          throw err; // or handle err
      }
      JSZip.loadAsync(data).then((zip)=>{
        var re = /(.jpg|.png|.gif|.ps|.jpeg)$/;
        var promises = Object.keys(zip.files).filter((fileName)=>{
          // don't consider non image files
          return fileName.startsWith('images/') && re.test(fileName.toLowerCase());
        }).map(function (fileName) {
          var file = zip.files[fileName];
          return file.async("blob").then((blob)=>{
            return {
              fileName,  // keep the link between the file name and the content
              blobUrl:URL.createObjectURL(blob) // create an url. img.src = URL.createObjectURL(...) will work
            };
          });
        });
        var json = zip.files['pregenerique.json'];
        promises.push(json.async("string").then((string)=>{
          return {
            fileName:'pregenerique.json',  // keep the link between the file name and the content
            json:JSON.parse(string) // create an url. img.src = URL.createObjectURL(...) will work
          };
        }));
        // `promises` is an array of promises, `Promise.all` transforms it
        // into a promise of arrays
        return Promise.all(promises);
      })
      .then((files)=>{
        const anim=files.find((o)=>o.fileName==='pregenerique.json');
        if (anim) {
          anim.json.assets.forEach((asset) => {
            const path=asset.u+asset.p;
            const img=files.find((o)=>o.fileName===path);
            if (img) {
              asset.u='';
              asset.p=img.blobUrl;
            }
          });
        }
        setAnimation(anim.json);
      });
    });
  };
  useEffect(()=>{
    if(loaded===100 && app.animation && !animation && !init.current) {
      const url=Conf.filesUrl+app.animation[0].url;
      const animZip=file(url);
      console.log(animZip);
      getEntries(animZip);
    }
  },[loaded,app,file,animation])
  useEffect(()=>{
    if (animation) {
      console.log(animation);
    }
  },[animation]);
  const handleDone=useCallback(()=>{
    setDone(true);
  },[setDone]);
  useEffect(()=>{
    console.log(mixerOk,done);
    if (mixerOk && done) {
      clearContext('animation');
      onDone();
    }
  },[mixerOk,done,onDone,clearContext]);
  useEffect(()=>{
    if (!mixerOk && !wait && animation) {
      setHelper('touch');
    } else {
      setHelper(null);
    }
  },[mixerOk,animation,wait,setHelper]);
  useEffect(()=>{
    if(!done) {
      const context=opener ? 'opener' : (!animation || wait || !mixerOk ? 'splashscreen' : (mixerOk ? 'animation' : 'splashscreen'));
      if (context!==contextCache.current) {
        console.log(context);
        contextCache.current=context;
        if(context==='animation') {
          console.log('trigger animation');
          triggerSon('animation');
        }
        setAppState(state=>{return {...state,appMessageContext:context}});
        return ()=>{
          setAppState(state=>{return {...state,appMessageContext:null}});
        }
      }
    }
  },[mixerOk,done,opener,animation,wait,setAppState,clearContext,triggerSon]);
  useEffect(()=>{
    const handleKeyPress=(e)=>{
      if (e.key==='s') {
        setOpener(false);
        setWait(false);
        handleDone();
      }
    }
    window.addEventListener('keypress',handleKeyPress);
    return ()=>{
      window.removeEventListener('keypress',handleKeyPress);
    }
  },[handleDone]);
  return <div className={'loader'+( loaded===100 ?' loaded' : '')+( done ?' done' : '')}>
    <div className="click-needed"></div>
    {(!animation || wait || !mixerOk) && <div className='loader-splashScreen'>
      {app.splashscreen && app.splashscreen[0] && <img alt='splashscreen' src={Conf.filesUrl+app.splashscreen[0].url+'-2000'} onLoad={handleSplashLoad}/>}
      <div className={"opener"+((opener || !splashLoad )?'':' hide')}>
        <video src="/app-assets/opener2.mp4" playsInline muted autoPlay onEnded={()=>setOpener(false)}/>
      </div>
    </div>}
    {mixerOk && animation && !wait && <div className="animation-wrapper">
      <div className='loader-animation'>
        <Lottie
        animationData={animation}
        play
        loop={false}
        style={{ width: '100%', height: '100%' }}
        onComplete={handleDone}
        />
        {!done && skipDelay && <div className="skip" onClick={handleDone}></div>}
      </div>
    </div>}
    {!opener && splashLoad && <div className='loader-progress'>
      <div className="chargement">
        {chargement || 'chargement...'}
      </div>
      <div className="loader-bar-container">
        <div className="loader-bar" style={{width:loaded+'%'}}/>
      </div>
    </div>}
  </div>;
}

export default Loader;
