import React, { useState,useRef,useEffect,useCallback} from "react";
import Avatar from './Avatar';

function ConversationHeader({ date,interlocuteurs }) {
  const [ active, setActive ] = useState(null);
  const [ nom, setNom ] = useState('');
  const [ show, setShow ] = useState(false);
  const timer=useRef(null);
  const timer2=useRef(null);
  useEffect(()=>{
    if (active) {
      if (timer.current) clearTimeout(timer.current);
      if (timer2.current) clearTimeout(timer2.current);
      setNom(active.nom);
      setShow(true);
    } else {
      if (timer.current) clearTimeout(timer.current);
      if (timer2.current) clearTimeout(timer2.current);
      timer.current=setTimeout(()=>{
        setShow(false);
      },1000);
      timer2.current=setTimeout(()=>{
        setNom('');
      },1500);
    }
  },[active]);
  const handleEnter=useCallback((interlocuteur)=>()=>setActive(interlocuteur),[setActive]);
  const handleLeave=useCallback(()=>setActive(null),[setActive]);
  return <div className="conversation-header">
    <div className={'conversation-titre'+(show ? ' show' : '')}>
      <div className='conversation-date'>{date}</div>
      <div className='conversation-interlocuteur'>{nom}</div>
    </div>
    <div className='conversation-interlocuteurs'>
      {interlocuteurs.map((interlocuteur)=><Avatar key={interlocuteur._id} interlocuteur={interlocuteur} onEnter={handleEnter(interlocuteur)} onLeave={handleLeave}/>)}
    </div>
  </div>;
}
export default ConversationHeader;
