import React, { useContext,useEffect,useState,useCallback,useMemo } from 'react';
import "./MainMenu.scss";
import { ModelContext } from "providers/ModelProvider";
import { MixContext } from "shared/providers/MixProvider";
import { SettingsContext } from "providers/SettingsProvider";
import { SharedSettingsContext } from "shared/providers/SharedSettingsProvider";
import Lien from 'shared/components/Lien';
import { PreloadContext } from "providers/PreloadProvider";
import Page from "./Page";
import Menu from "shared/components/Menu";
import { useLocation } from "react-router-dom";
import { menuPages } from 'shared/Utils';

const MainMenu = () => {
  const { file } = useContext(PreloadContext);
  const [ lien, setLien ] = useState(null);
  const { getCollection } = useContext(ModelContext);
  const { muteAll, setMuteAll } = useContext(MixContext);
  const { appState, setAppState } = useContext(SettingsContext);
  const { setAppState:setSharedAppState } = useContext(SharedSettingsContext);
  const [ key,setKey ]=useState(null);
  const { pathname }=useLocation();
  const application=useMemo(()=>getCollection('application'),[getCollection]);
  const app=useMemo(()=>application[0] || null,[application]);
  useEffect(()=>{
    if (appState.mainMenu.show) setSharedAppState(state=>{ return {...state,hiddenMenuLayers:['base']}});
    else setSharedAppState(state=>{ return {...state,hiddenMenuLayers:[]}});
  },[appState.mainMenu,setSharedAppState])
  const hideMainMenu=useCallback(()=>{
    setKey(null);
    setAppState((state)=>{return {...state,mainMenu:{...state.mainMenu, show:false}}});
  },[setAppState]);
  const contextMenu=appState.contextMenu ? appState.contextMenu.map((o)=>{return {
    ...o,
    onClick:()=>{
      hideMainMenu();
      o.onClick();
    }
  }}) : [];
  const topMenuPage=[{className:'close',onClick:()=>setKey(null)}];
  const topMenu=[{className:'close',onClick:hideMainMenu}];
  const bottomMenu=[{className:muteAll ? 'audio-off' : 'audio-on',onClick:()=>setMuteAll(state=>!state)},...contextMenu];
  const handleClick=(k)=>(e)=>{
    e.stopPropagation();
    k && setKey(k);
  }
  useEffect(()=>{
    hideMainMenu();
  },[pathname,hideMainMenu])
  return app ? <div className={'main-menu'+(appState.mainMenu.show ? ' show' : '')}>
      <div className='main-menu-inner'>
        <div>
        {menuPages.map((k,i)=><React.Fragment key={k}>
          {k==='aide' && <div className={"main-menu-item menu-spacer"+(key ?' hide':'')}></div>}
          <div className={"main-menu-item"+(key && k!==key ?' hide':'')+(key && k===key ?' active':'')} onClick={handleClick(k)}>{app[k+'_titre']}</div>
        </React.Fragment>)}
        </div>
        <Page k={key} setLien={setLien}/>
      </div>
      {key ? <>
        <Menu type='top' justify='flex-end' items={topMenuPage} fullWidth layer='modal'/>
      </>:<>
        <Menu type='top' justify='flex-end' items={topMenu} fullWidth layer='modal'/>
        <Menu type='bottom' items={bottomMenu} layer='modal'/>
      </>}
      <Lien lien={lien} onClose={()=>setLien(null)} file={file}/>
    </div> : '';
}
export default MainMenu;
