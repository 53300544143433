import React, { useContext,useState,useEffect,useCallback,useMemo,useRef } from "react";
import "./Lettre.scss";
import { ModelContext } from "providers/ModelProvider";
import { SettingsContext } from "providers/SettingsProvider";
import { useParams,useNavigate } from "react-router-dom";
import Tapuscrit from 'components/Tapuscrit';
import LettreInner from "shared/components/Lettre";
import Menu from "shared/components/Menu";
import Plus from "components/Plus";
import { MixContext } from "shared/providers/MixProvider";
import { PreloadContext } from "providers/PreloadProvider";
import { HelperContext } from "providers/HelperProvider";

function Lettre() {
  const { id } = useParams();
  const { triggerSon,mixerOk,mixerOn,mixerOff,setCurrentTime } = useContext(MixContext);
  const { appState, setAppState } = useContext(SettingsContext);
  const { getDoc } = useContext(ModelContext);
  const { file } = useContext(PreloadContext);
  const { setHelper } = useContext(HelperContext);
  const doc=getDoc(id);
  const navigate=useNavigate();
  const [tapuscritShow,setTapuscritShow] = useState(false);
  const [hide,setHide] = useState(false);
  const [playState,setPlayState] = useState('stop');
  const [progress,setProgress] = useState(0);
  const [ plus, setPlus ] = useState(false);
  const timer=useRef(null);
  useEffect(()=>{
    timer.current=setTimeout(()=>{
      setHelper('zoom');
    },10000);
    return ()=>{
      clearTimeout(timer.current);
      setHelper('null');
    }
  },[setHelper]);
  const params=useMemo(()=>{
    if(doc) {
      const {x:xDoc,y:yDoc,scale:scaleDoc}=doc;
      return appState.lettresParams ? appState.lettresParams[doc._id] || {x:xDoc,y:yDoc,scale:scaleDoc} : {x:xDoc,y:yDoc,scale:scaleDoc};
    }
    return {x:0,y:0,scale:5}
  },[appState,doc]);
  const setParams=useCallback((x,y,scale)=>{
    setAppState((state)=>{
      return {
        ...state,
        lettresParams:{
          ...state.lettresPositions,
          [id]:{x,y,scale},
        }
      }
    });
  },[setAppState,id]);
  const handlePlusClose=useCallback(()=>setPlus(false),[setPlus]);
  const setPosition=useCallback((x,y)=>{
    setParams(x,y,params.scale);
  },[setParams,params]);
  const setScale=useCallback((scale)=>{
    setParams(params.x,params.y,scale);
  },[setParams,params]);
  const play=useCallback(()=> {
    triggerSon('lettrePlay/'+id);
  },[triggerSon,id]);
  const pause=useCallback(()=> {
    console.log('pause','lettrePause/'+id);
    triggerSon('lettrePause/'+id);
  },[triggerSon,id]);
  const stop=useCallback(()=> {
    console.log('stop','lettreStop/'+id);
    triggerSon('lettreStop/'+id);
  },[triggerSon,id]);
  useEffect(()=>{
    if(mixerOk) {
      const handlePlay=()=>{
        setPlayState('play');
      }
      const handlePause=()=>{
        setPlayState('pause');
      }
      const handleStop=()=>{
        setPlayState('stop');
      }
      const handleTimeUpdate=(data)=>{
        setProgress(data.progress)
      }
      mixerOn({hook:'lettrePlay/'+id,eventName:'play',cb:handlePlay});
      mixerOn({hook:'lettrePlay/'+id,eventName:'pause',cb:handlePause});
      mixerOn({hook:'lettrePlay/'+id,eventName:'stop',cb:handleStop});
      mixerOn({hook:'lettrePlay/'+id,eventName:'timeupdate',cb:handleTimeUpdate});
      return ()=>{
        mixerOff({hook:'lettrePlay/'+id,eventName:'play',cb:handlePlay});
        mixerOff({hook:'lettrePlay/'+id,eventName:'pause',cb:handlePause});
        mixerOff({hook:'lettrePlay/'+id,eventName:'stop',cb:handleStop});
        mixerOff({hook:'lettrePlay/'+id,eventName:'timeupdate',cb:handleTimeUpdate});
      }
    }
  },[mixerOn,mixerOff,id,mixerOk]);
  const progressCb=useCallback((p)=>{
    setCurrentTime({hook:'lettrePlay/'+id,p});
  },[id,setCurrentTime]);
  const hideMenu=useCallback(()=>{
    setHide(true);
  },[setHide]);
  const showMenu=useCallback(()=>setHide(false),[setHide]);
  const showMainMenu=useCallback(()=>
    setAppState((state)=>{return {...state,mainMenu:{...state.mainMenu, show:true}}})
  ,[setAppState]);
  const topMenu=useMemo(()=>[{className:'home',onClick:()=>navigate('/themes')},{className:'menu',onClick:showMainMenu}],[showMainMenu,navigate]);
  useEffect(()=>{
    setAppState((state)=>{return {
      ...state,
      contextMenu:[
        {className:'portrait',onClick:()=>navigate('/player/'+doc.portraitId)},
      ]
    }});
    return ()=>{
      setAppState((state)=>{return {
        ...state,
        contextMenu:[]
      }});
    }
  },[setAppState,id,navigate,doc.portraitId]);
  const bottomMenu=useMemo(()=>[
    {className:'voix',onClick:()=>play(), condition:playState==='stop'},
    {className:'pause',onClick:()=>pause(), condition:playState==='play', secondary:{className:'stop',onClick:()=>stop()}},
    {className:'play',onClick:()=>play(), condition:playState==='pause', secondary:{className:'stop',onClick:()=>stop()}},
    {className:'tapuscrit',onClick:()=>setTapuscritShow(true)},
    {className:'docs',onClick:()=>navigate('/lettres/'+doc.portraitId)},
    {className:'plus',onClick:()=>setPlus(true)},
  ],[navigate,setTapuscritShow,setPlus,play,pause,stop,playState,doc.portraitId]);
  return doc && params ? <div className={'lettre'+(hide ? ' menu-hide' : '')}>
      <LettreInner id={id} onInteraction={()=>{clearTimeout(timer.current);setHelper(null);}} onPointerDown={hideMenu} onPointerUp={showMenu} setScale={setScale} setPosition={setPosition} position={params} scale={params.scale} file={file}/>
      <Menu type='top' justify='space-between' items={topMenu} fullWidth/>
      <Menu type='bottom' justify='space-evenly' items={bottomMenu}/>
      {playState!=='stop' && <Menu type='progress' progress={progress} progressCb={progressCb}/>}
      <Tapuscrit lettre={doc} show={tapuscritShow} onClose={()=>setTapuscritShow(false)}/>
      <Plus portraitId={doc.portraitId} show={plus} onClose={handlePlusClose} file={file}/>
  </div> : '';
}

export default Lettre;
