const fadeDuration=2000;
const step=1000;
const fade=({gainNode, volume, context, duration})=>{
  const currentTime = context.currentTime;
  const startVolume = gainNode.gain.value;
  const fadeIn=volume-startVolume>0;
  const steps=duration*step;
  const delta = 1 / steps; // number of volume changes per step
  const curve= fadeIn ?
    makeEqualPowerCurveIn(startVolume,volume,steps)
    :
    makeEqualPowerCurveOut(startVolume,volume,steps)
  ;
  try {
    // Cancel any potentially overlapping fade automations
    gainNode.gain.cancelScheduledValues(currentTime);
    curve.forEach((v, i) => {
      gainNode.gain.setValueAtTime(
        v,
        currentTime + i*delta*duration
      )
    });
    // Ensure final value is exact
    gainNode.gain.setValueAtTime(volume, currentTime + duration+0.01)
  } catch (error) {
    console.log(error);
  }
}
const makeEqualPowerCurveIn=(startVolume, endVolume,steps)=>{
  const curve = new Float32Array(steps);
  var range = endVolume - startVolume;
  curve[0] = startVolume;
  for (var i = 1; i < curve.length; i++)
    curve[i] = startVolume + range * Math.cos((1 - i / (curve.length-1)) * 0.5*Math.PI);
  return curve;
}
const makeEqualPowerCurveOut=(startVolume, endVolume, steps)=>{
  const curve = new Float32Array(steps);
  var range = startVolume - endVolume;
  for (var i = 0; i < curve.length; i++)
    curve[i] = endVolume + range * Math.cos(i / (curve.length-1) * 0.5*Math.PI);
  curve[curve.length - 1] = endVolume;
  return curve;
}
class Player {
  constructor ({src,context,track,trackVolume,volume,type}) {
    this.context=context;
    this.type=type;
    this.buffer=null;
    this.src=src;
    this.hook=null;
    this._onend=[];
    this._onfade=[];
    this._onload=[];
    this._onstop=[];
    this._onplay=[];
    this._onpause=[];
    this._ontimeupdate=[];
    this.currentProgress=0;
    this.fadeInterval=null;
    this.track = track;
    this.trackVolume = trackVolume;
    this.playerGain = this.context.createGain();
    this.playerGain.gain.value=volume;
    this.gainNode = this.context.createGain();
    this.gainNode.connect(this.playerGain);
    this.gainNode.gain.value=0;
    this.paused=true;
    this.stopAsked=false;
    this.playAsked=false;
    this.pauseAsked=false;
  }
  fade(v,duration) {
    this.gainNode.gain.cancelScheduledValues(this.context.currentTime);
    this.gainNode.gain.setValueAtTime(this.gainNode.gain.value, this.context.currentTime);
    this.gainNode.gain.linearRampToValueAtTime(v, this.context.currentTime + duration/1000);
  }
  on(event, fn, once) {
    var events = this['_on' + event];
    if (typeof fn === 'function') {
      events.push(once ? {fn: fn, once: once} : {fn: fn});
    }
    return this;
  }
  off(event, fn, id) {
    var events = this['_on' + event];
    var i = 0;
    if (fn) {
      // Loop through event store and remove the passed function.
      for (i=0; i<events.length; i++) {
        if (fn === events[i].fn) {
          events.splice(i, 1);
          break;
        }
      }
    } else if (event) {
      // Clear out all events of this type.
      this['_on' + event] = [];
    } else {
      // Clear out all events of every type.
      var keys = Object.keys(this);
      for (i=0; i<keys.length; i++) {
        if ((keys[i].indexOf('_on') === 0) && Array.isArray(this[keys[i]])) {
          this[keys[i]] = [];
        }
      }
    }
    return this;
  }
  once(event, fn) {
    // Setup the event listener.
    this.on(event, fn, 1);
    return this;
  }
  _emit(event, msg) {
    var events = this['_on' + event];
    // Loop through event store and fire all functions.
    for (var i=events.length-1; i>=0; i--) {
      events[i].fn(msg);
      if (events[i].once) {
        this.off(event, events[i].fn);
      }
    }
    return this;
  }
}
class RegularPlayer extends Player {
  constructor ({src,type,context,track,trackVolume,volume}) {
    super({src,type,context,track,trackVolume,volume});
    this.stopAsked=false;
    this.pauseAsked=false;
    this.playAsked=false;
    this.playing=false;
    this.buffer=null;
    this.source=null;
    this.startedAt=0;
    this.pausedAt=0;
    this.duration=0;
    this.preventTimeupate=false;
  }
  init(cb){
    if (!this.buffer) {
      var request = new XMLHttpRequest();
      request.responseType = 'arraybuffer';
      request.open('GET', this.src+'-44100.mp3', true);
      request.setRequestHeader('Range', '0-');
      request.addEventListener('load', ()=>{
        this.context.decodeAudioData(request.response, (buffer)=>{
          this.buffer=buffer;
          this.duration=buffer.duration;
          //console.log('buffer Ok',src);
          if (!this.stopAsked) cb();
          request=null;
        }, ()=>{
          console.log('audio ctx error');
          request=null;
        });
      })
      request.onerror = function() {
        console.log('errror loading sound')
        request=null;
      }
      request.send();
    } else {
      if (!this.stopAsked) cb();
    }
  }
  clear(){
    if (this.buffer) {
      try {
        this.playerGain.disconnect(this.trackVolume);
      } catch(e) {
        console.log(e);
      }
      if (this.source) {
        this.source.disconnect();
        this.source.buffer=null;
      }
      this.buffer=null;
    }
    clearInterval(this.timeUpdate);
  }
  play({hook=null,duration=fadeDuration}) {
    this.preventTimeupate=false;
    this.stopAsked=false;
    this.pauseAsked=false;
    this.playAsked=true;
    this.hook=hook;
    this._emit('play',{});
    const doPlay=()=>{
      this.playerGain.connect(this.trackVolume);
      this.gainNode.gain.cancelScheduledValues(this.context.currentTime);
      this.gainNode.gain.setValueAtTime(0, this.context.currentTime);
      this.source=this.context.createBufferSource(); // creates a sound source
      this.source.addEventListener('ended',this.clear.bind(this));
      this.source.buffer = this.buffer; // tell the source which sound to play
      this.source.connect(this.gainNode);
      this.startedAt = Date.now() - this.pausedAt;
      this.source.start(0, this.pausedAt / 1000);
      this.fade(1,50);
      this.playing=true;
      this.timeUpdate=setInterval(()=>{
        if (!this.preventTimeupate) {
          const currentTime=(Date.now() - this.startedAt)/1000;
          this._emit('timeupdate',{currentTime,progress:this.buffer ? 100*currentTime/this.buffer.duration : 0});
        }
      },100);
    }
    if (!this.playing) this.init(doPlay.bind(this));
    else {
      this.gainNode.gain.cancelScheduledValues(this.context.currentTime);
      this.fade(1,duration);
    }
  }
  setCurrentTime(p){
    this.pausedAt = p*this.duration*1000;
    if (this.playing) {
      this.preventTimeupate=true;
      this.fade(0,50);
      this.stopTimeout=setTimeout(()=>{
        this.playing=false;
        this.source.stop(0);
        setTimeout(()=>this.play({hook:this.hook,duration:50}),100);
      },150);
    }
  }
  stop({hook=null,duration=fadeDuration}) {
    if (this.playing) {
      this.fade(0,duration);
      this.stopTimeout=setTimeout(()=>{
        this.playing=false;
        this.source.stop(0);
        this.pausedAt = 0;
        this._emit('stop',{});
        this._emit('timeupdate',{currentTime:0,progress:0});
        this.clear();
      },duration+100);
    } else {
      this.playing=false;
      this.pausedAt = 0;
      this._emit('stop',{});
      this._emit('timeupdate',{currentTime:0,progress:0});
      this.clear();
    }
    this.playAsked=false;
    this.stopAsked=true;
  }
  pause({hook=null,duration=fadeDuration}) {
    if (this.playing) {
      this.fade(0,duration);
      this.stopTimeout=setTimeout(()=>{
        this.playing=false;
        this.source.stop(0);
        this.pausedAt = Date.now() - this.startedAt;
        this._emit('pause',{});
      },duration+100);
    } else {
      this.pausedAt = Date.now() - this.startedAt;
      this._emit('pause',{});
    }
    this.playAsked=false;
    this.pauseAsked=true;
  }
}
class LoopPlayer extends Player {
  constructor ({src,type,context,track,trackVolume,volume,crossFadeDuration}) {
    super({src,type,context,track,trackVolume,volume});
    this.stopAsked=false;
    this.pauseAsked=false;
    this.playAsked=false;
    this.playing=false;
    this.buffer=null;
    this.sources=[null,null];
    this.gainNode2 = this.context.createGain();
    this.gainNode2.connect(this.playerGain);
    this.gainNode2.gain.value=0;
    this.gainNodes=[this.gainNode,this.gainNode2];
    this.currentSource=0;
    this.crossFadeDuration=crossFadeDuration;
    this.loopTimeout=null;
  }
  init(cb){
    if (!this.buffer) {
      var request = new XMLHttpRequest();
      request.responseType = 'arraybuffer';
      request.open('GET', this.src+'-44100.mp3', true);
      request.setRequestHeader('Range', '0-');
      request.addEventListener('load', ()=>{
        this.context.decodeAudioData(request.response, (buffer)=>{
          this.buffer=buffer;
          //console.log('buffer Ok',src);
          if (!this.stopAsked) cb();
          request=null;
        }, ()=>{
          console.log('audio ctx error');
          request=null;
        });
      })
      request.onerror = function() {
        console.log('errror loading sound')
        request=null;
      }
      request.send();
    } else {
      if (!this.stopAsked) cb();
    }
  }
  fade(i,volume,duration) {
    const gainNode=this.gainNodes[i];
    //console.log(i,volume,duration)
    fade({gainNode, volume, context:this.context, duration:duration/1000});
  }
  clear(){
    if (this.buffer) {
      try {
        this.playerGain.disconnect(this.trackVolume);
      } catch(e) {
        console.log(e);
      }
      this.sources.forEach((source) => {
        if (source) {
          source.disconnect();
          source.buffer=null;
        }
      });
      this.buffer=null;
    }
  }
  play({hook=null,duration=fadeDuration}) {
    this.stopAsked=false;
    this.pauseAsked=false;
    this.playAsked=true;
    this.hook=hook;
    const doPlay=()=>{
      this.playerGain.connect(this.trackVolume);
      this.gainNodes[this.currentSource].gain.setValueAtTime(0, this.context.currentTime);
      this.sources[this.currentSource]=this.context.createBufferSource(); // creates a sound source
      this.sources[this.currentSource].buffer = this.buffer; // tell the source which sound to play
      this.sources[this.currentSource].connect(this.gainNodes[this.currentSource]);
      this.sources[this.currentSource].start(0);
      this.fade(this.currentSource,1,this.crossFadeDuration)
      this.playing=true;
      this.loopTimeout=setTimeout(()=>{
        this.fade(this.currentSource,0,this.crossFadeDuration);
        this.currentSource=this.currentSource===0 ? 1 : 0;
        doPlay();
      },this.buffer.duration*1000-this.crossFadeDuration);
    }
    if (!this.playing) this.init(doPlay.bind(this));
  }
  stop({hook=null,duration=fadeDuration}) {
    if (this.buffer) {
      if (this.playing) {
        clearTimeout(this.loopTimeout);
        this.fade(this.currentSource,0,duration);
        this.stopTimeout=setTimeout(()=>{
          this.playing=false;
          if (this.sources[0]) this.sources[0].stop(0);
          if (this.sources[1]) this.sources[1].stop(0);
          this.clear();
        },duration+100);
      } else {
        if (this.sources[0]) this.sources[0].stop(0);
        if (this.sources[1]) this.sources[1].stop(0);
        this.clear();
      }
    }
    this.playAsked=false;
    this.stopAsked=true;
  }
  pause({hook=null,duration=fadeDuration}) {
    this.stop({hook,duration});
  }
}
class PlayOutPlayer extends Player {
  constructor ({src,type,context,track,trackVolume,volume}) {
    super({src,type,context,track,trackVolume,volume});
    this.playing=false;
    this.buffer=null;
    this.clearTimeout=null;
  }
  init(cb){
    if (!this.buffer) {
      var request = new XMLHttpRequest();
      request.responseType = 'arraybuffer';
      request.open('GET', this.src+'-44100.mp3', true);
      request.setRequestHeader('Range', '0-');
      request.addEventListener('load', ()=>{
        this.context.decodeAudioData(request.response, (buffer)=>{
          this.buffer=buffer;
          //console.log('buffer Ok',src);
          cb();
          request=null;
        }, ()=>{
          console.log('audio ctx error');
          request=null;
        });
      })
      request.onerror = function() {
        console.log('errror loading sound')
        request=null;
      }
      request.send();
    } else {
      cb();
    }
  }
  clear(){
    try {
      this.playerGain.disconnect(this.trackVolume);
    } catch(e) {
      console.log(e);
    }
    this.buffer=null;
  }
  play({hook=null,duration=fadeDuration}) {
    this.stopAsked=false;
    this.pauseAsked=false;
    this.playAsked=true;
    this.hook=hook;
    const doPlay=()=>{
      this.playerGain.connect(this.trackVolume);
      this.gainNode.gain.cancelScheduledValues(this.context.currentTime);
      this.gainNode.gain.setValueAtTime(1, this.context.currentTime);
      const source=this.context.createBufferSource(); // creates a sound source
      source.addEventListener('ended',()=>{
        source.disconnect();
        source.buffer=null;
      });
      source.buffer = this.buffer; // tell the source which sound to play
      source.connect(this.gainNode);
      source.start(0);
      clearTimeout(this.clearTimeout);
      this.clearTimeout=setTimeout(this.clear.bind(this),this.buffer ? this.buffer.duration*1000 : 1000);
    }
    this.init(doPlay.bind(this));
  }
  stop({hook=null,duration=fadeDuration}) {
    this.fade(0,duration);
  }
}
class Mixer {
  constructor({unlock}) {
    this.context=null;
    this.masterMute=null;
    this.master=null;
    this.players=[];
    this.unlocked=false;
    this.onUnlock=unlock || (()=>{});
    this.context=null;
    this.trackVolume={};
    this.inited=false;
  }
  init(sons){
    //console.log(sons);
    if (!this.inited) {
      this.context = new (window.AudioContext || window.webkitAudioContext)({sampleRate:44100,latencyHint:'playback'});
      this.context.onstatechange=()=>{
        if (this.context.state!=='closed') {
          //console.log('state change !!!');
          this.unlock();
        }
      };
      this.masterMute = this.context.createGain();
      this.masterMute.connect(this.context.destination);
      this.masterMute.gain.setValueAtTime(1, this.context.currentTime);
      this.master = this.context.createGain();
      this.master.connect(this.masterMute);
      this.master.gain.setValueAtTime(1, this.context.currentTime);
      [...Array(20).keys()].forEach((trackIdx) => {
        const g=this.context.createGain();
        g.connect(this.master);
        g.gain.setValueAtTime(1, this.context.currentTime);
        this.trackVolume['track-'+trackIdx]=g;
      });
    }
    const { context,master } = this;
    console.log(this.players);
    sons.forEach((son,i) => {
        son.listeners.forEach((listener) => {
          const { track, action } =  listener;
          const { volume, loop, url } =  son;
          const type= loop ? 'loop' : (action==='play out' ? 'play out' : 'regular');
          const p=this.players.find((o)=>o.src===son.url && o.track===listener.track && o.type===type);
          if (!p && son.url) {
            let player;
            if(type==='regular') player = new RegularPlayer({src:url,type,context,master,track,trackVolume:this.trackVolume[track],volume,crossFadeDuration:3000});
            if(type==='play out') player = new PlayOutPlayer({src:url,type,context,master,track,trackVolume:this.trackVolume[track],volume,crossFadeDuration:3000});
            if(type==='loop') player = new LoopPlayer({src:url,type,context,master,track,trackVolume:this.trackVolume[track],volume,crossFadeDuration:3000});
            this.players.push(player);
            //console.log('added',son.url,player);
          }
        });
    });
    this.inited=true;
  }
  setTrackVolume(track,v){
    this.trackVolume[track].gain.setValueAtTime(v, this.context.currentTime);
  }
  setVolume(v){
    this.master.gain.setValueAtTime(v, this.context.currentTime);
  }
  unlock(){
    if (this.context.state!=='running') {
      console.log('need unlock');
      this.onUnlock();
    } else if (!this.unlocked) {
      this.unlocked=true;
      console.log('audio context running, all good !');
    }
  }
  getPlayer(url,track,type){
    return this.players.find((o)=>o.src===url && o.track===track && o.type===type);
  }
  play(url,track,type,hook){
    //console.log('mixer play')
    const player=this.getPlayer(url,track,type);
    if (player) player.play({hook});
    if (type!=='play out') {
      this.players.forEach((p, i) => {
        if (p.track===track && p.type!=='play out' && p!==player) p.stop({hook});
      });
    }
  }
  playCut(url,track,type,hook){
    //console.log('mixer playcut')
    const player=this.getPlayer(url,track,type);
    if (player) player.play({hook,duration:50});
    if (type!=='play out') {
      this.players.forEach((p, i) => {
        if (p.track===track && p.type!=='play out' && p!==player) p.stop({hook});
      });
    }
  }
  pause(url,track,type,hook){
    const player=this.getPlayer(url,track,type);
    if (player) player.pause({hook});
  }
  pauseCut(url,track,type,hook){
    //console.log('pauseCut');
    const player=this.getPlayer(url,track,type);
    if (player) player.pause({hook,duration:50});
  }
  stop(url,track,type,hook){
    const player=this.getPlayer(url,track,type);
    if (player) player.stop({hook});
  }
  stopCut(url,track,type,hook){
    const player=this.getPlayer(url,track,type);
    if (player) player.stop({hook,duration:50});
  }
  mute(){
    this.master.gain.cancelScheduledValues(this.context.currentTime);
    this.master.gain.setValueAtTime(this.master.gain.value, this.context.currentTime);
    this.master.gain.linearRampToValueAtTime(0, this.context.currentTime + fadeDuration/1000);
  }
  unMute(){
    this.master.gain.cancelScheduledValues(this.context.currentTime);
    this.master.gain.setValueAtTime(this.master.gain.value, this.context.currentTime);
    this.master.gain.linearRampToValueAtTime(1, this.context.currentTime + fadeDuration/1000);
  }
  muteAll(){
    this.masterMute.gain.cancelScheduledValues(this.context.currentTime);
    this.masterMute.gain.setValueAtTime(this.masterMute.gain.value, this.context.currentTime);
    this.masterMute.gain.linearRampToValueAtTime(0, this.context.currentTime + fadeDuration/1000);
  }
  unMuteAll(v){
    this.masterMute.gain.cancelScheduledValues(this.context.currentTime);
    this.masterMute.gain.setValueAtTime(this.masterMute.gain.value, this.context.currentTime);
    this.masterMute.gain.linearRampToValueAtTime(1, this.context.currentTime + fadeDuration/1000);
  }
  on({url,track,type,eventName,cb}){
    const player=this.getPlayer(url,track,type);
    if (player) player.on(eventName,cb);
  }
  off({url,track,type,eventName,cb}){
    const player=this.getPlayer(url,track,type);
    if (player) player.off(eventName,cb);
  }
  setCurrentTime({url,track,type,p}){
    const player=this.getPlayer(url,track,type);
    if (player) player.setCurrentTime(p);
    //console.log(player);
  }
}
export default Mixer;
