import React, { useMemo,useContext,useRef,useCallback,useState,useEffect } from 'react';
import "./Menu.scss";
import { SharedSettingsContext } from "shared/providers/SharedSettingsProvider";

const Menu = ({items, progressCb=()=>{}, type='top', progress=0, fullWidth=false, justify='space-evenly', layer='base', hideLayers=[], inline=false}) => {
  const { appState } = useContext(SharedSettingsContext);
  const [ localP,setLocalP ] = useState(0);
  const show=useMemo(()=>appState.hiddenMenuLayers.indexOf(layer)===-1,[appState.hiddenMenuLayers,layer]);
  const p=useRef(0);
  const x0=useRef(0);
  const dx=useRef(0);
  const dp=useRef(0);
  const moving=useRef(false);
  const node=useRef(null);
  const handleMouseMove=useCallback((e)=>{
    const containerNode=node.current.parentNode.parentNode;
    dx.current=(e.clientX-x0.current)/appState.scale;
    dp.current=dx.current/containerNode.clientWidth;
    node.current.parentNode.style.width=((Math.max(0,Math.min(1,p.current+dp.current)))*containerNode.clientWidth)+'px';
  },[appState.scale]);
  const handleMouseUp=useCallback((e)=>{
    progressCb(Math.max(0,Math.min(1,p.current+dp.current)));
    moving.current=false;
    document.body.style.touchAction=undefined;
    document.body.removeEventListener('pointermove',handleMouseMove);
    document.body.removeEventListener('pointerup',handleMouseUp);
    x0.current=0;
    p.current=0;
    dx.current=0;
    dp.current=0;
  },[handleMouseMove,progressCb]);
  const handleMouseDown=useCallback((e)=>{
    e.stopPropagation();
    node.current=e.currentTarget;
    const containerNode=node.current.parentNode.parentNode;
    document.body.style.touchAction='none';
    document.body.addEventListener('pointermove',handleMouseMove);
    document.body.addEventListener('pointerup',handleMouseUp);
    p.current=node.current.parentNode.clientWidth/containerNode.clientWidth;
    x0.current=e.clientX;
    moving.current=true;
  },[handleMouseMove,handleMouseUp]);
  useEffect(()=>{
    if(!moving.current) setLocalP(progress);
  },[progress,setLocalP]);
  return <div className={'menu-container-'+type+(inline ? ' inline':'')}>
      <div className={'menu-inner'+(fullWidth ? ' full-width':'')+(show?' show':'')} style={{justifyContent:justify}}>
        {type!=='progress'? items.filter((o)=>o.condition===undefined || o.condition).map((item)=>{
          if (item.secondary) return <div key={item.id || item.className} className="combo">
            <div className={'menu-item menu-'+item.className} onPointerUp={item.onClick}></div>
            <div className={'menu-item menu-item-secondary menu-'+item.secondary.className} onPointerUp={item.secondary.onClick}></div>
          </div>;
          return <div key={item.id || item.className} className={'menu-item menu-'+item.className} onPointerUp={item.onClick} onClick={(e)=>e.stopPropagation()}></div>;
        })
        :
        <div className='menu-progress-container'>
          <div className='menu-progress-wrapper'>
            <div className='background'>
            </div>
            <div className='menu-progress-inner' style={{width:localP+'%'}}>
              <div className='target' onPointerDown={handleMouseDown} onTouchStart={(e)=>{e.stopPropagation();}}>
                <div className='target-inner'>
                </div>
              </div>
            </div>
          </div>
        </div>}
      </div>
    </div>;
}
export default Menu;
