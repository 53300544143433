import React, { useContext,useState,useEffect,useCallback,useMemo,useRef} from "react";
import "./Player.scss";
import ConversationPlayer from 'shared/components/ConversationPlayer';
import { ModelContext } from "providers/ModelProvider";
import { PreloadContext } from "providers/PreloadProvider";
import { SettingsContext } from "providers/SettingsProvider";
import { HelperContext } from "providers/HelperProvider";
import Menu from "shared/components/Menu";
import { useParams, useNavigate } from "react-router-dom";

function Player() {
  const { id } = useParams();
  const navigate = useNavigate();
  const { modele,getCollection,history } = useContext(ModelContext);
  const { file } = useContext(PreloadContext);
  const { setHelper } = useContext(HelperContext);
  const { setAppState } = useContext(SettingsContext);
  const [ step,setStep ] = useState(history && history[id] ? history[id].step : 0);
  const [ done,setDone ] = useState(false);
  const lettres = getCollection('lettre').filter((o)=>o.portraitId===id);
  const timer=useRef(null);
  useEffect(()=>{
    timer.current=setTimeout(()=>{
      setHelper('touch');
    },10000);
    return ()=>{
      clearTimeout(timer.current);
      setHelper('null');
    }
  },[setHelper]);
  const handleTap=useCallback(()=>{
    clearTimeout(timer.current);
    setHelper('null');
    timer.current=setTimeout(()=>{
      setHelper('touch');
    },10000);
    setStep(step=>step+1);
  },[setStep,setHelper]);
  const handleDone=useCallback(()=>{
    setDone(true);
  },[setDone]);
  const showMainMenu=useCallback(()=>setAppState((state)=>{
    return {...state,mainMenu:{...state.mainMenu, show:true}}
  }),[setAppState]);
  const topMenu=useMemo(()=>[{className:'home',onClick:()=>navigate('/themes')},{className:'menu',onClick:showMainMenu}],[showMainMenu,navigate]);
  useEffect(()=>{
    setAppState((state)=>{return {
      ...state,
      contextMenu:[
        {className:'lettres',onClick:()=>navigate('/lettres/'+id)},
      ]
    }});
    return ()=>{
      setAppState((state)=>{return {
        ...state,
        contextMenu:[]
      }});
    }
  },[setAppState,id,navigate]);
  const bottomMenu=useMemo(()=>[
    {className:'lettres',opacity:1,onClick:()=>navigate('/lettre/'+lettres[0]._id)},
  ],[navigate,lettres]);
  useEffect(()=>{
    setAppState(state=>{return {...state,appMessageContext:'portrait/'+id}});
    return ()=>{
      setAppState(state=>{return {...state,appMessageContext:null}});
    }
  },[setAppState,id]);
  return <div className='player-container'>
    <ConversationPlayer modele={modele} portraitId={id} step={step} onTap={handleTap} onDone={handleDone} file={file}>
    {done && lettres.length>0 && <Menu type='bottom' justify='space-evenly' items={bottomMenu} inline/>}
    </ConversationPlayer>
    <Menu type='top' justify='space-between' items={topMenu} fullWidth/>
  </div>;
}

export default Player;
