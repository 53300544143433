import React, { useState, createContext, useEffect } from "react";

export const SettingsContext = createContext({});

const SettingsProvider = ({children})=>{
  const [ appState,setAppState ] = useState({
    mainMenu:{
      show:false,
      contextMenu:[],
    }
  });
  useEffect(()=>{
    const handleOrientation = (m)=>{
      if (m.matches) {
        console.log('portrait');
        setAppState(state=>{return{...state,orientation:'portrait'}});
      } else {
        console.log('landscape');
        setAppState(state=>{return{...state,orientation:'landscape'}});
      }
    }
    window.matchMedia('(orientation: portrait)').addListener(handleOrientation);
    handleOrientation(window.matchMedia('(orientation: portrait)'));
    return ()=>{
      window.matchMedia('(orientation: portrait)').removeListener(handleOrientation);
    }
  },[setAppState]);
  return (
        <SettingsContext.Provider value={{appState,setAppState}}>
            {children}
        </SettingsContext.Provider>
    );
}
export default SettingsProvider;
