import React, {useContext, useMemo, useRef, useEffect} from 'react';
import "./MainMenu.scss";
import { ModelContext } from "providers/ModelProvider";
import { HelperContext } from "providers/HelperProvider";

function Glossaire({setLien}) {
  const { setHelper } = useContext(HelperContext);
  const { getCollection } = useContext(ModelContext);
  const liens=useMemo(()=>getCollection('lien')||[],[getCollection]);
  const timer=useRef(null);
  useEffect(()=>{
    timer.current=setTimeout(()=>{
      setHelper('touch');
    },10000);
    return ()=>{
      clearTimeout(timer.current);
      setHelper('null');
    }
  },[setHelper]);
  return liens.length>0 ? <div className="glossaire">
    {liens.map((l)=><h3 key={l.slug} onClick={()=>{clearTimeout(timer.current);setHelper(null);setLien(l);}}>{l.titre}</h3>)}
  </div> : '';
}
export default Glossaire;
