import React, { useContext,useEffect } from "react";
import "./Modal.scss";
import Menu from 'shared/components/Menu';
import { SharedSettingsContext } from "shared/providers/SharedSettingsProvider";

function Modal({content,show,onClose,preventClick=false}) {
  const { setAppState } = useContext(SharedSettingsContext);
  let classes='modal-mask';
  if (show) {
    classes+=' on';
  } else classes+=' off';
  const handleClose=(e)=>{
    e.stopPropagation();
    if (!preventClick && e.target===e.currentTarget) {
      setTimeout(()=>onClose(e),100);
    }
  }
  useEffect(()=>{
    if (show) setAppState(state=>{ return {...state,hiddenMenuLayers:['base']}});
    else setAppState(state=>{ return {...state,hiddenMenuLayers:[]}});
  },[show,setAppState])
  return <div className={classes}>
      <div className='modal-inner'>
        {content}
      </div>
      <Menu type='bottom' justify='space-evenly' items={[{className:'close',onClick:handleClose}]} layer='modal'/>
    </div>;
}

export default Modal;
