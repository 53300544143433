import React, { useState, createContext } from "react";
export const SharedSettingsContext = createContext({});

const SharedSettingsProvider = ({children})=>{
  const [ appState,setAppState ] = useState({
    hiddenMenuLayers:[],
  });
  return (
    <SharedSettingsContext.Provider value={{appState,setAppState}}>
        {children}
    </SharedSettingsContext.Provider>
  );
}
export default SharedSettingsProvider;
