import React, {useContext} from "react";
import "./Home.scss";
import {
  Link,
} from "react-router-dom";
import { ModelContext } from "providers/ModelProvider";

function Home() {
  const { getCollection } = useContext(ModelContext);
  const portraits = getCollection('portrait');
  const lettres = getCollection('lettre');
  return <div className='home'>
    <Link to={'/'}>landing</Link><br />
    <Link to={'/themes'}>themes</Link><br />
    {portraits.map((portrait)=>{
      const portraitLettres=lettres.filter((o)=>o.portraitId===portrait._id);
      return <div key={portrait._id}>
        <Link to={'/player/'+portrait._id}>{portrait.nom}</Link>
        {portraitLettres.map((lettre)=><div key={lettre._id}><Link to={'/lettre/'+lettre._id}>{lettre.date} {lettre.from} > {lettre.to}</Link></div>)}
      </div>;
    })}
  </div>;
}

export default Home;
