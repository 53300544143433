import React, {useContext, useState, useEffect, useMemo, useRef } from "react";
import "./Landing.scss";
import {
  useNavigate
} from "react-router-dom";
import { ModelContext } from "providers/ModelProvider";
import { PreloadContext } from "providers/PreloadProvider";
import { MixContext } from "shared/providers/MixProvider";
import Bulle from "shared/components/ConversationPlayer/Bulle";
import Conf from 'Conf';
import { SettingsContext } from "providers/SettingsProvider";
import { HelperContext } from "providers/HelperProvider";

const contextId='landing';

function Landing() {
  const { setHelper } = useContext(HelperContext);
  const { setAppState } = useContext(SettingsContext);
  const { triggerSon,clearContext } = useContext(MixContext);
  const { getCollection } = useContext(ModelContext);
  const { file } = useContext(PreloadContext);
  const application=useMemo(()=>getCollection('application'),[getCollection]);
  const app=useMemo(()=>application[0] || {},[application]);
  const texts=getCollection('interface');
  const text1=texts.find((o)=>o.slug==='landing_bulle1');
  const text2=texts.find((o)=>o.slug==='landing_bulle2');
  const [ step, setStep ] = useState(0);
  const navigate=useNavigate();
  const timer=useRef(null);
  useEffect(()=>{
    timer.current=setTimeout(()=>{
      setHelper('touch');
    },10000);
    return ()=>{
      clearTimeout(timer.current);
      setHelper('null');
    }
  },[setHelper]);
  useEffect(()=>{
    if (step>2) navigate('/themes');
  },[step,navigate])
  useEffect(()=>{
    triggerSon('landing');
  },[triggerSon]);
  useEffect(()=>{
    console.log('landing');
    if (step===1) {
      triggerSon('landing_bulle1');
    }
    if (step===2) {
      triggerSon('landing_bulle2');
    }
  },[step,triggerSon]);
  const handleClick=(e)=>{
    clearTimeout(timer.current);
    setHelper('null');
    if (step<2) setStep(step=>step+1);
    else {
      navigate('/themes');
    };
  }
  useEffect(()=>{
    return ()=>{
      clearContext(contextId);
    }
  },[clearContext]);
  useEffect(()=>{
    setAppState(state=>{return {...state,appMessageContext:'landing'}});
    return ()=>{
      setAppState(state=>{return {...state,appMessageContext:null}});
    }
  },[setAppState]);
  return <div className='landing' onPointerDown={handleClick} style={{backgroundImage:'url('+file(Conf.filesUrl+app.background[0].url)+')'}}>
    <div className='landing-mask'></div>
    <div className='logo'>
      <img src={file('/app-assets/logo.svg')} alt='suppliques stories'/>
      <div className="landing-messages">
        <div className='landing-message landing-message-right' style={{transform:step>=1 ? 'scale(1)' : 'scale(0)'}}>
          <div className='landing-message-content'>
            <Bulle text={text1 ? text1.texte_long : ''} side='right' file={file}/>
          </div>
        </div>
        <div className='landing-message landing-message-left' style={{transform:step>=2 ? 'scale(1)' : 'scale(0)'}}>
          <div className='landing-message-content'>
            <Bulle text={text2 ? text2.texte_long : ''} side='left' file={file}/>
          </div>
        </div>
      </div>
    </div>
  </div>;
}

export default Landing;
