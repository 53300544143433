import React, {useLayoutEffect, useContext, useState, useCallback, useMemo} from "react";
import './App.scss';
import 'shared/styles.scss';

import Landing from 'components/Landing';
import Themes from 'components/Themes';
import Home from 'components/Home';
import Loader from 'components/Loader';
import Player from 'components/Player';
import Lettres from 'components/Lettres';
import Lettre from 'components/Lettre';
import MainMenu from 'components/MainMenu';
import Messages from 'components/Messages';
import OrientationMask from 'components/OrientationMask';
import { PreloadContext } from "providers/PreloadProvider";
import Scaler from "shared/components/Scaler";
import { SettingsContext } from "providers/SettingsProvider";
import unmuteAudio from 'unmute-ios-audio';

import {
  Routes,
  Route,
} from "react-router-dom";
unmuteAudio();

function App() {
  const [ animDone,setAnimDone ] = useState(false);
  const { filesReady,filesProgress } = useContext(PreloadContext);
  const { appState,setAppState } = useContext(SettingsContext);
  const handleSizeChange=useCallback((ratio)=>{
    setAppState((state)=>{return{...state,ratio}});
  },[setAppState]);
  useLayoutEffect(()=>{
    const handleContextMenu=(event)=>{
       event.preventDefault();
       event.stopPropagation();
       return false;
    }
    window.addEventListener('contextmenu',handleContextMenu);
    return ()=>{
      window.removeEventListener('contextmenu',handleContextMenu);
    }
  },[]);
  const handleDone=useCallback(()=>setAnimDone(true),[setAnimDone]);
  const ready=useMemo(()=>filesReady,[filesReady]);
  return <>
      <Scaler onSizeChange={handleSizeChange}/>
      <div className={"app "+appState.orientation}>
      {ready && animDone && <>
        <Routes>
          <Route path="/themes" element={<Themes />} />
          <Route path="/lettres/:id" element={<Lettres />} />
          <Route path="/lettre/:id" element={<Lettre />} />
          <Route path="/player/:id" element={<Player />} />
          <Route path="/dev" element={<Home/>} />
          <Route path="/" element={<Landing />} />
          <Route path="*" element={<Landing />} />
        </Routes>
        <MainMenu/>
        </>
      }
      <Loader loaded={filesProgress} onDone={handleDone}/>
      <Messages/>
      <OrientationMask/>
      </div>
    </>;
}

export default App;
