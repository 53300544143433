import React, {useContext, useEffect, useMemo, useCallback, useRef, useState } from "react";
import "./Themes.scss";
import {
  useNavigate
} from "react-router-dom";
import { ModelContext } from "providers/ModelProvider";
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react';
import SwiperCore, {
  Navigation,
  Pagination,
} from 'swiper';
import 'swiper/swiper.scss';
import 'swiper/modules/navigation/navigation.scss';
import Conf from 'Conf';
import { MixContext } from "shared/providers/MixProvider";
import Menu from "shared/components/Menu";
import Progress from "./Progress";
import { SettingsContext } from "providers/SettingsProvider";
import { PreloadContext } from "providers/PreloadProvider";

SwiperCore.use([Navigation, Pagination]);

const contextId='themes';
const indexMap=(i)=>(i===0 ? 0 : (
  i===1 ? 1 : (
    i===2 ? 1 : (
      2
    )
  )
));
function Themes() {
  const { triggerSon,clearContext } = useContext(MixContext);
  const { getCollection,history } = useContext(ModelContext);
  const { appState, setAppState } = useContext(SettingsContext);
  const { file } = useContext(PreloadContext);
  const [ actualHistory, setActualHistory ] = useState({});
  const application=useMemo(()=>getCollection('application'),[getCollection]);
  const app=useMemo(()=>application[0] || {},[application]);
  const portraits=useMemo(()=>getCollection('portrait'),[getCollection]);
  const themes=useMemo(()=>getCollection('theme').filter((theme)=>{
    const ps=portraits.filter((o)=>o.themeId===theme._id);
    return ps.length>0;
  }),[getCollection,portraits]);
  const portraitSwiper=useRef(null);
  const enabledSwiper=useMemo(()=>appState.ratio>1.2,[appState.ratio]);
  useEffect(()=>{
    if (portraitSwiper.current){
      if(enabledSwiper) {
        portraitSwiper.current.enable();
      } else {
        portraitSwiper.current.slideTo(1,0);
        portraitSwiper.current.disable();
      }
    }
  },[enabledSwiper]);
  const themeId=useMemo(()=>appState.themeId || (themes[0] ? themes[0]._id : null),[appState.themeId,themes]);
  const themeIdx=useMemo(()=>themeId ? themes.findIndex((o)=>o._id===themeId) : null,[themes,themeId]);
  const list=useMemo(()=>portraits.filter((o)=>o.themeId===themeId),[portraits,themeId]);
  const portraitId=useMemo(()=>appState.portraitId ? appState.portraitId[themeId] : null,[appState.portraitId,themeId]);
  const portraitIdx=useMemo(()=>portraitId ? list.findIndex((o)=>o._id===portraitId) : null,[list,portraitId]);
  const handlePortraitsSwiper=useCallback((swiper)=>{
    portraitSwiper.current=swiper;
    if(!enabledSwiper) {
      portraitSwiper.current.slideTo(1,0);
      portraitSwiper.current.disable();
    } else {
      const actualIndex=portraitIdx ? indexMap(portraitIdx) : 1;
      console.log(portraitIdx,actualIndex)
      portraitSwiper.current.slideTo(actualIndex,0);
    }
  },[enabledSwiper,portraitIdx]);
  const setThemeId=useCallback((id)=>{
    setAppState((state)=>{return {...state,themeId:id}});
  },[setAppState]);
  const setPortraitId=useCallback((id)=>{
    if (themeId) setAppState((state)=>{return {...state,portraitId:{...state.portraitId,[themeId]:id}}});
  },[setAppState,themeId]);
  const handleSlideChange=useCallback((swiper)=>{
    if (themes.length>0) setThemeId(themes[(swiper.activeIndex) % themes.length]._id);
  },[setThemeId,themes]);
  useEffect(()=>{
    triggerSon('themes');
  },[triggerSon]);
  useEffect(()=>{
    triggerSon('theme/'+themeId);
  },[themeId,triggerSon]);
  useEffect(()=>{
    setActualHistory({});
    setTimeout(()=>setActualHistory(history),1);
  },[themeId,setActualHistory,history]);
  const showMainMenu=()=>setAppState((state)=>{return {...appState,mainMenu:{...appState.mainMenu, show:true}}});
  const topMenu=[{className:'menu',onClick:showMainMenu}];
  useEffect(()=>{
    return ()=>{
      clearContext(contextId);
    }
  },[clearContext]);
  useEffect(()=>{
    setAppState(state=>{return {...state,appMessageContext:'themes'}});
    return ()=>{
      setAppState(state=>{return {...state,appMessageContext:null}});
    }
  },[setAppState]);
  const navigate=useNavigate();
  const handleClick=(i,portraitId)=>()=>{
    const actualIndex=indexMap(i);
    setPortraitId(portraitId);
    console.log(portraitSwiper.current.activeIndex,i,actualIndex);
    if (enabledSwiper && portraitSwiper.current.slides.length>3 && portraitSwiper.current.activeIndex!==actualIndex) {
      const go=()=>{
        setTimeout(()=>navigate('/player/'+portraitId),200);
        portraitSwiper.current.off('slideChangeTransitionEnd',go);
      };
      portraitSwiper.current.on('slideChangeTransitionEnd',go);
      portraitSwiper.current.slideTo(actualIndex);
    } else {
      navigate('/player/'+portraitId);
    }
  }
  return <div className='themes' style={{backgroundImage:'url('+file(Conf.filesUrl+app.background[0].url)+')'}}>
    <div className='themes-mask'></div>
    <div className='themes-inner'>
      <div className='themes-chooser'>
          <Swiper
            navigation
            pagination={{clickable:true}}
            spaceBetween={0}
            slidesPerView={1}
            initialSlide={themeIdx ? themeIdx : 0}
            onSlideChange={handleSlideChange}
          >
            {themes.map((theme)=><SwiperSlide key={theme._id}>
                <div className="theme-inner">
                {theme.titre}
                </div>
              </SwiperSlide>
            )}
          </Swiper>
      </div>
      <div className={'portraits-chooser'+(list.length>=4 ? ' shift-left' : '')}>
          <Swiper
            key={themeId}
            spaceBetween={0}
            slidesPerView={4}
            initialSlide={portraitIdx ? portraitIdx : (list.length>2 ? 1 : 0)}
            centeredSlides={list.length>2}
            centerInsufficientSlides={list.length<=2}
            onSwiper={handlePortraitsSwiper}
          >
            {list.map((portrait,i)=><SwiperSlide key={portrait._id} onClick={handleClick(i,portrait._id)}>
              <div className='portrait-slide'>
                <div className='portrait-medaillon'>
                  {portrait.photo[0] && <div className='portrait-photo' style={{backgroundImage:`url(${file(Conf.filesUrl+portrait.photo[0].url+'-300')})`}}></div>}
                  <div className="portrait-progress">
                    <Progress progress={actualHistory[portrait._id] ? actualHistory[portrait._id].progress : 0}/>
                  </div>
                </div>
                <div className='portrait-nom'>{portrait.nom}</div>
              </div>
            </SwiperSlide>)}
          </Swiper>
      </div>
    </div>
    <Menu type='top' justify='flex-end' items={topMenu} fullWidth/>
  </div>;
}

export default Themes;
