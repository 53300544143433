import React, {useContext, useMemo, useRef, useEffect} from 'react';
import "./MainMenu.scss";
import { ModelContext } from "providers/ModelProvider";
import { PreloadContext } from "providers/PreloadProvider";
import { TransformWrapper, TransformComponent } from "shared/lib/react-zoom-pan-pinch";
import Conf from 'Conf';
import { SharedSettingsContext } from "shared/providers/SharedSettingsProvider";
import { HelperContext } from "providers/HelperProvider";

function Chronologie() {
  const { appState } = useContext(SharedSettingsContext);
  const { getCollection } = useContext(ModelContext);
  const { file } = useContext(PreloadContext);
  const { setHelper } = useContext(HelperContext);
  const application=useMemo(()=>getCollection('application'),[getCollection]);
  const app=useMemo(()=>application[0] || {},[application]);
  const timer=useRef(null);
  useEffect(()=>{
    timer.current=setTimeout(()=>{
      setHelper('zoom-plus');
    },10000);
    return ()=>{
      clearTimeout(timer.current);
      setHelper('null');
    }
  },[setHelper]);
  return app.chronologie ? <TransformWrapper
    initialScale={0.7}
    minScale={appState.reverseWindowHeight/2000}
    maxScale={10}
    initialPositionX={(appState.reverseWindowWidth/2)-1000}
    initialPositionY={-400}
    limitToBounds={false}
    panning={{velocityDisabled:true}}
    // onZoom={(a)=>console.log(a.state)}
    //onPanningStop={(a)=>console.log(a.state)}
    onZoomStart={()=>{clearTimeout(timer.current);setHelper(null);}}
    onPanningStart={()=>{clearTimeout(timer.current);setHelper(null);}}
    >
      <TransformComponent>
        <div className="chronologie-viewport">
          {app.chronologie.length>0 && ( app.chronologie[0].type==='image/svg+xml' ?
            <object data={file(Conf.filesUrl+app.chronologie[0].url+'.svg')} type="image/svg+xml" aria-label="chronologie"/>
            : <img src={file(Conf.filesUrl+app.chronologie[0].url+'-2000')} alt="chronologie" />
          )}
        </div>
      </TransformComponent>
    </TransformWrapper> : '';
}
export default Chronologie;
