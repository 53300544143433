const width=500;
const height=800;

const Conf={
  width,
  height,
  ratio:(100*height/width)+'%',
  css:[
    {key:'height',value:height+'px'},
    {key:'width',value:width+'px'},
    {key:'ratio',value:(height/width)+''},
  ],
}

export default Conf;
