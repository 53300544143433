import React, { useState, createContext, useContext } from "react";
import './HelperProvider.scss';
import TouchAnim from './assets/tap-push';
import ZoomAnim from './assets/zoom-push';
import Lottie from 'react-lottie-player'
import { SettingsContext } from "providers/SettingsProvider";

export const HelperContext = createContext({});

const HelperProvider = ({children})=>{
  const { appState } = useContext(SettingsContext);
  const [ helper,setHelper ] = useState(null);
  return (
    <HelperContext.Provider value={{setHelper,helper}}>
        {children}
        <div className={"helper "+appState.orientation+(helper ? ' '+helper : '')}>
          {helper==="touch" && <div className="touch-helper">
            <Lottie
            animationData={TouchAnim}
            play
            loop={true}
            style={{ width: '100%', height: '100%' }}
            />
          </div>}
          {(helper==="zoom" || helper==="zoom-plus") && <div className="zoom-helper">
            <Lottie
            animationData={ZoomAnim}
            play
            loop={true}
            style={{ width: '100%', height: '100%' }}
            />
          </div>}
        </div>
    </HelperContext.Provider>
  );
}
export default HelperProvider;
