import React, {useState,useContext,useMemo,useEffect,useRef,useCallback} from 'react';
import "./Messages.scss";
import { ModelContext } from "providers/ModelProvider";
import { SettingsContext } from "providers/SettingsProvider";
import MuiMarkdown from 'mui-markdown';
import "shared/components/MarkDown.scss";
import { parseIcons } from 'shared/Utils';

const played = [];


function Messages() {
  const { getCollection,setHistory,history } = useContext(ModelContext);
  const { appState } = useContext(SettingsContext);
  const [ messages,setMessages ] = useState([]);
  const [ message,setMessage ] = useState(null);
  const timers=useRef([]);
  const appMessagesAll=useMemo(()=>getCollection('appMessage'),[getCollection]);
  const context=useMemo(()=>appState.appMessageContext,[appState.appMessageContext]);
  const appMessages=useMemo(()=>appMessagesAll.filter((o)=>played.indexOf(o._id+'-'+context)===-1 && (o.display!=='localstorage' || !history[o._id+'-'+context]) && o.hooks.indexOf(context)!==-1).map((m)=>{return{...m,uid:m._id+'-'+context}}),[appMessagesAll,context,history]);
  useEffect(()=>{
    setMessages([...appMessages])
  },[appMessages,setMessages]);
  const next=useCallback(()=>{
    setMessage(null);
    setMessages(state=>{
      // eslint-disable-next-line
      const [_,...others]=state;
      return [...others];
    })
  },[setMessage,setMessages]);
  useEffect(()=>{
    if (messages.length>0 && played.indexOf(messages[0].uid)===-1) {
      const m=messages[0];
      played.push(m.uid);
      const timer1=setTimeout(()=>{
        setMessage(m);
        if (m.display==='localstorage') {
          setHistory(state=>{return {...state,[m.uid]:true}});
        }
        const timer2=setTimeout(()=>{
          next()
        },m.duree);
        timers.current.push(timer2);
      },2000);
      timers.current.push(timer1);
    }
  },[messages,setMessage,setHistory,next]);
  useEffect(()=>{
    if (message) {
      console.log(message.hooks,context);
      if(message.hooks.indexOf(context)) next();
    }
  },[context,message,next]);
  useEffect(()=>{
    timers.current.forEach((timer) => {
      clearTimeout(timer);
    });
  },[]);
  return <div className='messages'>
    {appMessagesAll.map((m)=><div key={m._id} onClick={next} className={"message "+m.msgType+(message && m._id===message._id ?' active':'')}>
      {m.msgType==='avertissement' && <div className="warning"></div>}
      <div className="text">
        <MuiMarkdown>{parseIcons(m.texte,m.images)}</MuiMarkdown>
      </div>
    </div>)}
  </div>;
}
export default Messages;
