import React, { useRef, useEffect, useContext } from 'react';
import "./Scaler.scss";
import PlayerConf from 'shared/Conf';
import { SharedSettingsContext } from "shared/providers/SharedSettingsProvider";

const Scaler = ({onSizeChange=undefined}) => {
  const { setAppState } = useContext(SharedSettingsContext);
  const el=useRef(null);
  useEffect(()=>{
    const root = document.documentElement;
    PlayerConf.css.forEach(rule => root.style.setProperty('--'+rule.key, rule.value));
    const handleResize=()=>{
      const sizes={};
      if (el.current) {
        const { width,height } = el.current.getBoundingClientRect();
        const ratio=height/width;
        const R=PlayerConf.height/PlayerConf.width;
        let scale=1;
        let left=0;
        let top=0;
        if (ratio<R) {
          scale=height/PlayerConf.height;
        } else {
          scale=width/PlayerConf.width;
        }
        left=(width-PlayerConf.width*scale)/2;
        top=(height-PlayerConf.height*scale)/2;
        sizes.pixelSize=1.2/scale;
        sizes.scale=scale;
        sizes.left=left;
        sizes.top=top;
        sizes.actualWidth=PlayerConf.width*scale;
        sizes.actualHeight=PlayerConf.height*scale;
        sizes.reverseWindowWidth=window.innerWidth/scale;
        sizes.reverseWindowHeight=window.innerHeight/scale;
        root.style.setProperty('--pixelSize', sizes.pixelSize+'px');
        root.style.setProperty('--ratio', scale);
        root.style.setProperty('--reverseRatio', 1/scale);
        root.style.setProperty('--left', left+'px');
        root.style.setProperty('--top', top+'px');
        root.style.setProperty('--actualWidth', sizes.actualWidth+'px');
        root.style.setProperty('--actualHeight', sizes.actualHeight+'px');
        root.style.setProperty('--reverseWindowWidth', sizes.reverseWindowWidth+'px');
        root.style.setProperty('--reverseWindowHeight', sizes.reverseWindowHeight+'px');
        if (onSizeChange) onSizeChange(ratio);
      }
      sizes.screenHeight=window.innerHeight;
      sizes.screenWidth=window.innerWidth;
      root.style.setProperty('--screenHeight', window.innerHeight+"px");
      root.style.setProperty('--screenWidth', window.innerWidth+"px");
      setAppState(state=>{return {...state,...sizes}})
    }
    const doResize=()=>{
      handleResize();
      setTimeout(handleResize,1000);
    }
    window.addEventListener('resize',doResize);
    window.matchMedia('(orientation: portrait)').addListener(doResize);
    handleResize();
    return ()=>{
      window.removeEventListener('resize',doResize);
      window.matchMedia('(orientation: portrait)').removeListener(doResize);
    }
  },[onSizeChange,setAppState]);
  return <div className="scaler" ref={el}>
    <div className="frame"></div>
  </div>;
}
export default Scaler;
