import React from "react";
import "./Tapuscrit.scss";
import { Scrollbars } from 'react-custom-scrollbars-2';
import Modal from 'shared/components/Modal';
import MuiMarkdown from 'mui-markdown';

function Tapuscrit({lettre,...otherProps}) {
  const content=<div className='tapuscrit-content'>
    <div className='lettre-surtitre'>Transcription tapuscrite</div>
    <div className='lettre-interlocuteurs'>De : <strong>{lettre.from}</strong><br/> À : <strong>{lettre.to}</strong></div>
    <div className='tapuscrit-text'>
      <Scrollbars
      style={{ position:'absolute', width: '100%', height: '100%' }}
      autoHide
      >
        <div className='tapuscrit-text-inner markdown-preview'><MuiMarkdown>{lettre.tapuscrit}</MuiMarkdown></div>
      </Scrollbars>
      <div className="top-gradient"></div>
      <div className="bottom-gradient"></div>
    </div>
  </div>;
  return <Modal content={content} {...otherProps}/>;
}

export default Tapuscrit;
