import React, {useContext} from 'react';
import "./MainMenu.scss";
import MuiMarkdown from 'mui-markdown';
import { ModelContext } from "providers/ModelProvider";
import { parseIcons } from 'shared/Utils';
import { Scrollbars } from 'react-custom-scrollbars-2';
import Chronologie from './Chronologie';
import Historique from './Historique';
import Glossaire from './Glossaire';
import "shared/components/MarkDown.scss";

function Page({k,setLien}) {
  const { getCollection } = useContext(ModelContext);
  const docCollection=getCollection('application');
  const doc=docCollection.length>0 ? docCollection[0] : null;
  const page=k && doc[k+'_titre'] ? {titre:doc[k+'_titre'],texte:doc[k+'_texte']||'',images:doc[k+'_images']||[]} : null;
  return <div className={"menu-page"+(page || k==='chronologie'?' active':'')}>
    {page && k!=='chronologie' && k!=='historique' && k!=='glossaire' && <div className='page-inner'>
      <Scrollbars
      className="scrollbars"
      autoHide
      >
        <div className={"scrollbars-inner markdown-preview mkdwn-"+k}>
          <MuiMarkdown>{parseIcons(page.texte,page.images)}</MuiMarkdown>
        </div>
      </Scrollbars>
      <div className="top-gradient"></div>
      <div className="bottom-gradient"></div>
    </div>}
    {k==='chronologie' && <div className='page-inner chronologie'>
      <Chronologie />
    </div>}
    {k==='historique' && <div className='page-inner historique'>
      <Scrollbars
      className="scrollbars"
      autoHide
      >
        <div className={"scrollbars-inner markdown-preview mkdwn-"+k}>
          <Historique />
        </div>
      </Scrollbars>
      <div className="top-gradient"></div>
      <div className="bottom-gradient"></div>
    </div>}
    {k==='glossaire' && <div className='page-inner glossaire'>
      <Scrollbars
      className="scrollbars"
      autoHide
      >
        <div className={"scrollbars-inner markdown-preview mkdwn-"+k}>
          <Glossaire setLien={setLien}/>
        </div>
      </Scrollbars>
      <div className="top-gradient"></div>
      <div className="bottom-gradient"></div>
    </div>}
  </div>;
}
export default Page;
