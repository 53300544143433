import React, {useContext} from 'react';
import "./OrientationMask.scss";
import { SettingsContext } from "providers/SettingsProvider";
import { SharedSettingsContext } from "shared/providers/SharedSettingsProvider";

function OrientationMask() {
  const settings = useContext(SettingsContext);
  const sharedSettings = useContext(SharedSettingsContext);
  const {orientation} = settings.appState;
  const {screenWidth,screenHeight} = sharedSettings.appState;
  const isMobile=Math.min(screenWidth,screenHeight)<500;
  const needMask=isMobile && orientation==='landscape';
  return <div className={'orientation-mask'+(needMask ? ' on':'')}>
  </div>;
}
export default OrientationMask;
