const initiales=(nom)=>{
  const tab=nom.split(' ');
  let res='';
  if (tab[0]) res+=tab[0][0].toUpperCase();
  if (tab[1]) res+=tab[1][0].toUpperCase();
  return res;
}
const prenom=(nom)=>{
  const tab=nom.split(' ');
  let res='';
  if (tab[0]) res+=tab[0];
  return res;
}
export { initiales,prenom };
