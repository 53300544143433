import React from "react";
import "./Progress.scss";
function Progress({progress=50}) {
  const r=48;
  const length=(progress/100)*2*Math.PI*r;
  const comp=(100-progress/100)*2*Math.PI*r;
  return <div className={'progress-container'+(progress===0 ? ' reset' : '')}>
    <svg viewBox="0 0 100 100">
      <circle style={{stroke:'#0005'}} cx="50" cy="50" r={r} fill="none" strokeWidth="2"/>
      {progress>=0 && <circle style={{strokeDasharray:`${length},${comp}`,stroke:'#FFF'}} cx="50" cy="50" r={r} fill="none" strokeWidth="2"></circle>}
    </svg>
  </div>;
}

export default Progress;
